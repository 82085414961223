import terms from 'assets/terms'
import { useAppDispatch, useAppSelector } from 'utils'
import { useEffect, useState } from 'react'
import { toggleRequestLeaveModal } from 'reducers/leaves/leaves'
import { getMyLeaves, deleteLeave } from 'reducers/leaves/leaves.thunk'
import { getUsersInfo, getValidatedByUsersInfo } from 'reducers/userInfo/userInfo.thunk'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SimpleButton, { ButtonStyle } from 'components/button'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import Collapse from '@mui/material/Collapse'
import Modal from 'components/modal'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import RequestModal from './requestModal'
import TableLeaves from './table'
import './style.scss'

export default function Leaves() {
  const dispatch = useAppDispatch()
  const { myLeaves } = useAppSelector(state => state.leaves)
  const { usersInfo, usersValidatedByInfo } = useAppSelector(state => state.userinfo)
  const [leaveId, setLeaveId] = useState<string>('')
  const [checked, setChecked] = useState(false)
  const [openArchived, setOpenArchived] = useState(false)
  const [leaveList, setLeaveList] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getMyLeaves()).then(res => {
      if (res.type.includes('fulfilled')) {
        setChecked(true)
      }
    })
  }, [])

  useEffect(() => {
    if (myLeaves) {
      const validatedByUsersIdList: string[] = myLeaves
        .filter(item => item.lastModifiedBy && item.lastModifiedBy !== null)
        .map(item => item.lastModifiedBy)
      dispatch(getValidatedByUsersInfo(validatedByUsersIdList)).then(res => {
        if (res.type.includes('fulfilled')) {
          setChecked(true)
        }
      })
    }
  }, [myLeaves])

  useEffect(() => {
    const updatedLeavesList = myLeaves?.map(item => {
      const matchingValidatedBy = usersValidatedByInfo?.find(thirdItem => thirdItem.id === item.lastModifiedBy)
      if (matchingValidatedBy) {
        return {
          ...item,
          validatedByLastName: matchingValidatedBy?.lastName,
          validatedByFirstName: matchingValidatedBy?.firstName,
        }
      }
      return item
    })
    setLeaveList(updatedLeavesList)
  }, [usersInfo, usersValidatedByInfo])

  const getFilteredLeaves = (type: string) => {
    let filteredList = [...leaveList]
    switch (type) {
      case 'GRANTED':
        filteredList = filteredList.filter(obj => obj.status === 'GRANTED' && !obj.isArchived)
        return filteredList
      case 'GRANTED_AND_SAVED':
        filteredList = filteredList.filter(obj => (obj.status === 'GRANTED' || 'PENDING') && !obj.isArchived)
        return filteredList
      case 'PENDING':
        filteredList = filteredList.filter(obj => obj.status === 'PENDING' && !obj.isArchived)
        return filteredList
      case 'DENIED':
        filteredList = filteredList.filter(obj => obj.status === 'DENIED' && !obj.isArchived)
        return filteredList
      case 'ARCHIVED':
        filteredList = filteredList.filter(obj => obj.isArchived)
        return filteredList
      default:
        return []
    }
  }

  const openDeleteModal = (id: string) => {
    setLeaveId(id)
    setShowDeleteModal(true)
  }

  const handleDeleteLeave = (id: string) => {
    dispatch(deleteLeave(id)).then(res => {
      if (res.type.includes('fulfilled')) {
        setShowDeleteModal(false)
        dispatch(getMyLeaves())
      }
    })
  }

  return (
    <div className="page leaves">
      <div className="header d-flex flex-column" style={{ fontSize: 14 }}>
        <div className="title">{terms.Page.leaves.title}</div>
        <div>{terms.Page.leaves.subtitle1}</div>
        <div>{terms.Page.leaves.subtitle2}</div>
        <div>{terms.Page.leaves.subtitle3}</div>
        <div className="d-flex align-items-center">
          <InfoOutlinedIcon />
          {terms.Page.leaves.subtitle4}
        </div>
      </div>
      <Fade in={checked}>
        <div className="wrapper mt-24">
          <div className="title">{terms.Page.leaves.validated}</div>
          <TableLeaves type="GRANTED" leaves={getFilteredLeaves('GRANTED_AND_SAVED')} onDelete={openDeleteModal} />
        </div>
      </Fade>
      <Fade in={checked}>
        <div className="wrapper mt-24">
          <div className="title">
            {terms.Page.leaves.inProcess}
            {' '}
            <SimpleButton
              text="Nouvelle demande"
              onClick={() => dispatch(toggleRequestLeaveModal(true))}
              sx={{ position: 'absolute', top: 0, right: 0 }}
              startIcon={<AddIcon />}
            />
          </div>
          <div className="d-flex align-items-center" style={{ marginTop: '15px', fontSize: 14 }}>
            <InfoOutlinedIcon sx={{ marginRight: 1 }} />
            En fonction de votre statut, il y a des démarches
            à effectuer en parallèle de votre demande sur l’application (Optimum, CRA...)
          </div>
          <TableLeaves type="PENDING" leaves={getFilteredLeaves('PENDING')} onDelete={openDeleteModal} />
        </div>
      </Fade>
      <Fade in={checked}>
        <div className="wrapper mt-24 mb-24">
          <div className="title">{terms.Page.leaves.denied}</div>
          <TableLeaves type="DENIED" leaves={getFilteredLeaves('DENIED')} />
        </div>
      </Fade>
      <Fade in={checked}>
        <div className="wrapper mt-24 mb-24">
          <div className="title">
            <Box display="flex" justifyContent="space-between">
              <div>
                {terms.Page.leaves.archived}
              </div>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenArchived(!openArchived)}
                >
                  {openArchived ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </Box>
          </div>
          <Collapse in={openArchived} timeout="auto" unmountOnExit>
            <TableLeaves type="ARCHIVED" leaves={getFilteredLeaves('ARCHIVED')} />
          </Collapse>
        </div>
      </Fade>
      <RequestModal />
      <Modal
        title="Suppression de la demande d’absence"
        bodyText="Êtes-vous sûr de vouloir supprimer votre demande d’absence ?"
        handleClose={() => setShowDeleteModal(false)}
        onButtonClick={() => handleDeleteLeave(leaveId)}
        buttonStyle={ButtonStyle.error}
        open={showDeleteModal}
        buttonText="Oui, supprimer"
      />
    </div>
  )
}
