import { Leave } from 'reducers/leaves/types'
import { Category } from 'types/leaves'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import SchoolIcon from '@mui/icons-material/School'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

type Props = {
  leaves: Leave[]
  type: string
  onDelete?: (string) => void
}

const statusChip = (status: string, isArchived: boolean) => {
  switch (status) {
    case 'PENDING': {
      const backgroundColor = (isArchived) ? '#DADADA' : '#f1dfc6'
      const color = (isArchived) ? '#797979' : '#bd5601'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<AccessTimeOutlinedIcon style={{ color }} />}
          label="En attente"
        />
      )
    }
    case 'DENIED': {
      const backgroundColor = (isArchived) ? '#DADADA' : '#f2caca'
      const color = (isArchived) ? '#797979' : '#BD2929'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<CloseIcon style={{ color }} />}
          label="Déclinée"
        />
      )
    }
    case 'GRANTED': {
      const backgroundColor = (isArchived) ? '#DADADA' : '#d7e7d3'
      const color = (isArchived) ? '#797979' : '#2c6c26'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<CheckIcon style={{ color }} />}
          label="Acceptée"
        />
      )
    }
    case 'SAVED': {
      const backgroundColor = (isArchived) ? '#DADADA' : '#d7e7d3'
      const color = (isArchived) ? '#797979' : '#2c6c26'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<EventAvailableIcon style={{ color }} />}
          label="Pris en compte"
        />
      )
    }
    default:
      return <div />
  }
}

const categoryChip = (category: string, isArchived: boolean) => {
  switch (category) {
    case Category.Vacation: {
      const backgroundColor = (isArchived) ? '#DADADA' : '#cae9f2'
      const color = (isArchived) ? '#797979' : '#217896'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<BeachAccessOutlinedIcon style={{ color }} />}
          label="Congés"
        />
      )
    }
    case Category.School: {
      const backgroundColor = (isArchived) ? '#DADADA' : '#F9F2FC'
      const color = (isArchived) ? '#797979' : '#C778E3'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<SchoolIcon style={{ color }} />}
          label="École"
        />
      )
    }
    case Category.Unavailable: {
      const backgroundColor = (isArchived) ? '#DADADA' : '#e8defb'
      const color = (isArchived) ? '#797979' : '#7760B9'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<EventBusyIcon style={{ color }} />}
          label="Indisponibilité"
        />
      )
    }
    case Category.Formation: {
      const backgroundColor = (isArchived) ? '#DADADA' : '#FBF9ED'
      const color = (isArchived) ? '#797979' : '#DAC44E'
      return (
        <Chip
          style={{ backgroundColor, borderRadius: 6, color }}
          icon={<AutoStoriesIcon style={{ color }} />}
          label="Formation"
        />
      )
    }
    default:
      return <div />
  }
}

const period = row => {
  if ((row.startDate === row.endDate) && (row.startTimeOfDay === row.endTimeOfDay)) {
    return (
      <div>
        {dayjs(row.startDate).locale('fr').format('DD MMMM')}
        {' '}
        -
        {' '}
        {row.startTimeOfDay === 'MORNING' ? 'matin' : 'après-midi'}
      </div>
    )
  }
  return (
    <div style={{ lineHeight: '0.8' }}>
      {dayjs(row.startDate).locale('fr').format('DD MMMM')}
      {' '}
      {row.startTimeOfDay === 'MORNING' ? 'matin' : 'après-midi'}
      <br />
      {' '}
      <span style={{ marginLeft: '40px' }}>-</span>
      {' '}
      <br />
      {dayjs(row.endDate).locale('fr').format('DD MMMM')}
      {' '}
      {row.endTimeOfDay === 'AFTERNOON' ? 'après-midi' : 'matin'}
    </div>
  )
}

export default function TableLeaves({ leaves, onDelete, type }: Props) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: 2 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={230}>Dates</TableCell>
            <TableCell width={180}>Type d’absence</TableCell>
            <TableCell width={200}>Statut</TableCell>
            {type !== 'PENDING' && type !== 'ARCHIVED' && (
              <TableCell>{type === 'GRANTED' ? 'Validée par' : 'Refusée par'}</TableCell>
            )}
            {type === 'ARCHIVED' && (
              <TableCell>Traitée par</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {leaves.map(row => (
            <TableRow
              key={row.id}
            >
              <TableCell component="th" scope="row" style={{ width: 220 }}>
                {period(row)}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {categoryChip(row.category, row.isArchived)}

              </TableCell>
              <TableCell>
                {statusChip(row.status, row.isArchived)}
              </TableCell>
              {type !== 'PENDING' && (
                <TableCell>
                  {row.status !== 'SAVED' && (
                    row.lastModifiedBy ? (
                      <div>
                        {row.validatedByFirstName}
                        {' '}
                        {row.validatedByLastName}
                      </div>
                    ) : 'Non renseigné'
                  )}
                </TableCell>
              )}
              {row.status !== 'DENIED' && !row.isArchived && (
                <TableCell align="right" sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                  <IconButton aria-label="delete" onClick={() => onDelete(row.id)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const defaultProps = {
  onDelete: () => { /* to implement */ },
}

TableLeaves.defaultProps = defaultProps
