import { Chip } from '@mui/material'
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import SchoolIcon from '@mui/icons-material/School'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import { Category } from 'types/leaves'
import { Absence } from '../agendaTable'
import './style.scss'

interface StatusChipProps {
  category: string;
  status: string;
  width: number;
  absence: Absence;
}

const categoryIcon = (category: string) => {
  switch (category) {
    case Category.Vacation:
      return <BeachAccessOutlinedIcon className="icon" />
    case Category.School:
      return <SchoolIcon className="icon" />
    case Category.Unavailable:
      return <EventBusyIcon className="icon" />
    case Category.Formation:
      return <AutoStoriesIcon className="icon" />
    default:
      return <div />
  }
}

const categoryLabel = (category: string) => {
  switch (category) {
    case Category.Vacation:
      return 'Congés'
    case Category.School:
      return 'École'
    case Category.Unavailable:
      return 'Indisponibilité'
    case Category.Formation:
      return 'Formation'
    default:
      return ''
  }
}

const chipCatStatName = (category: string, status: string) => {
  if (category === Category.Vacation) {
    return (status === 'GRANTED') ? 'granted' : 'pending'
  }
  return category.toLowerCase()
}

function StatusChip({
  category, status, width, absence,
}: StatusChipProps) {
  const morningClass = (absence.endTimeOfDay === 'MORNING') ? 'morning' : ''
  const afternoonClass = (absence.startTimeOfDay === 'AFTERNOON') ? 'afternoon' : ''
  const afternoonMorningClass = (absence.startTimeOfDay === 'AFTERNOON'
    && absence.endTimeOfDay === 'MORNING') ? 'afternoonMorning' : ''
  return (
    <Chip
      className={`chip ${chipCatStatName(category, status)} ${morningClass} ${afternoonClass} ${afternoonMorningClass}`}
      sx={{ borderRadius: '10px' }}
      label={width === 1 ? '' : categoryLabel(category)}
      icon={categoryIcon(category)}
    />
  )
}

export default StatusChip
