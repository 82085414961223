import MenuItem from '@mui/material/MenuItem'
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import SchoolIcon from '@mui/icons-material/School'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import { Category } from 'types/leaves'

type Props = {
  handleChange: (string) => void,
  value: string | null,
  label: string
}

export default function CategoryPickerComponent({ handleChange, value, label }: Props) {
  return (
    <FormControl fullWidth>
      <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
        {label}
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
      >
        <MenuItem key={1} value={Category.Vacation}>
          <BeachAccessOutlinedIcon style={{ color: '#217896' }} />
          <Typography ml={1} variant="inherit" style={{ fontWeight: 800, color: '#217896' }}>
            Congés
          </Typography>
        </MenuItem>
        <MenuItem key={1} value={Category.School}>
          <SchoolIcon style={{ color: '#C778E3' }} />
          <Typography ml={1} variant="inherit" style={{ fontWeight: 800, color: '#C778E3' }}>
            École
          </Typography>
        </MenuItem>
        <MenuItem key={1} value={Category.Unavailable}>
          <EventBusyIcon style={{ color: '#7760B9' }} />
          <Typography ml={1} variant="inherit" style={{ fontWeight: 800, color: '#7760B9' }}>
            Indisponibilité
          </Typography>
        </MenuItem>
        <MenuItem key={1} value={Category.Formation}>
          <AutoStoriesIcon style={{ color: '#DAC44E' }} />
          <Typography ml={1} variant="inherit" style={{ fontWeight: 800, color: '#DAC44E' }}>
            Formation
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  )
}
