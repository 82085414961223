/* eslint-disable max-len */
import {
  Table, TableBody, TableRow,
  TableCell, Grid, Avatar,
  Typography, TableContainer, Tooltip,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import terms from 'assets/terms'
import dayjs from 'dayjs'
import { readRole } from 'utils/humanRead'
import StatusChip from '../statusChip'
import './style.scss'

interface User {
  firstName: string;
  lastName: string;
  teamName: string;
  role: string;
}
export interface Absence {
  endTimeOfDay: string;
  startTimeOfDay: string;
  startDate: string;
  endDate: string;
  category: string;
  status: string;
  startMonth: boolean;
  id: string;
}

interface AgendaTableProps {
  user: User;
  daysInMonth: number;
  currentDate: dayjs.Dayjs;
  getAbsenceOnDay: (day: dayjs.Dayjs) => Absence[];
  calculateAbsenceDays: (startDate: string, endDate: string) => number;
  isFirstDayOfAbsence: (day: dayjs.Dayjs, absence: Absence) => boolean;
}

function AgendaTable({
  user, daysInMonth, currentDate, getAbsenceOnDay, calculateAbsenceDays, isFirstDayOfAbsence,
}: AgendaTableProps) {
  return (
    <TableContainer className="agenda-table-container" style={{ overflow: 'visible' }}>
      <Table className="table">
        <TableBody className="body">
          <TableRow className="row">
            <TableCell className="cell">
              <Grid container className="user-info-container">
                <Grid item className="user-avatar">
                  <Avatar className="avatar">
                    {user.firstName && user.lastName
                      ? user.firstName[0] + user.lastName[0]
                      : <AccountCircleIcon />}
                  </Avatar>
                </Grid>
                <Grid item className="user-name">
                  <Tooltip
                    placement="top-start"
                    title={`${user.firstName || terms.Page.agenda.unspecified} ${user.lastName || terms.Page.agenda.unspecified}`}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className="user-name">
                      {user.firstName || terms.Page.agenda.unspecified}
                      {' '}
                      {user.lastName || terms.Page.agenda.unspecified}
                    </div>
                  </Tooltip>
                  <Typography className="team-name">
                    {user.role !== 'USER' ? readRole(user.role) || terms.Page.agenda.unspecified : ''}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell className="absence-table-cell" sx={{ padding: '0 4px 0 0', width: '962px' }}>
              <Grid container spacing={1} className="user-absences-container">
                {Array.from({ length: daysInMonth }, (_, i) => {
                  const day = currentDate.startOf('month').add(i, 'day')
                  const absences = getAbsenceOnDay(day)
                  return (
                    <Grid
                      item
                      key={i}
                      className={`absence-date ${absences.length > 0
                        ? terms.Page.agenda.absentClass : terms.Page.agenda.presentClass}`}
                    >
                      <Typography
                        className={`indicator ${day.day() === 0 || day.day() === 6 ? 'weekend' : ''}`}
                      >
                        {String(i + 1).padStart(2, '0')}
                      </Typography>
                      {absences.map(absence => {
                        const absenceDays = calculateAbsenceDays(absence.startDate, absence.endDate)
                        const statusClass = absence.status.toLowerCase()
                        return isFirstDayOfAbsence(day, absence) && (
                          <div
                            className={`absence-indicator ${statusClass} `}
                            style={{
                              width: `${(absenceDays * 955.0) / daysInMonth}px`,
                              maxWidth: `${(absenceDays * 955.0) / daysInMonth}px`,
                            }}
                            key={absence.id}
                          >
                            <StatusChip category={absence.category} status={absence.status} width={absenceDays} absence={absence} />
                          </div>
                        )
                      })}
                    </Grid>
                  )
                })}
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default AgendaTable
