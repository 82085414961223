import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'utils'
import { toggleRequestLeaveModal } from 'reducers/leaves/leaves'
import { postLeave, getMyLeaves } from 'reducers/leaves/leaves.thunk'
import { styled } from '@mui/material/styles'
import SimpleButton, { ButtonStyle } from 'components/button'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import Typography from '@mui/material/Typography'
import CategoryPickerComponent from 'components/categoryPicker'
import DatePickerComponent from 'components/datePicker'
import DataPickerComponent from 'components/dataPicker'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { DateValidationError } from '@mui/x-date-pickers/models'
import dayjs, { Dayjs } from 'dayjs'
import { Category } from 'types/leaves'
import 'dayjs/locale/fr'

import './style.scss'

const LeavesDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    paddingTop: 0,
  },
  '& .MuiDialogActions-root': {},
}))

export default function RequestModal() {
  const { requestLeaveModalOpen, validated } = useAppSelector(state => state.leaves)
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs())
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs())
  const [startTimeOfDay, setStartTimeOfDay] = useState<string>('MORNING')
  const [endTimeOfDay, setEndTimeOfDay] = useState<string>('AFTERNOON')
  const [category, setCategory] = useState<string>(Category.Vacation)
  const [error, setError] = useState<DateValidationError | null>(null)
  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(toggleRequestLeaveModal(false))
  }

  useEffect(() => {
    setStartDate(dayjs())
    setEndDate(dayjs())
  }, [requestLeaveModalOpen])

  const handlePostLeave = () => {
    dispatch(postLeave({
      startDate: dayjs(startDate).locale('fr').format('YYYY-MM-DD').toString(),
      endDate: dayjs(endDate).locale('fr').format('YYYY-MM-DD').toString(),
      startTimeOfDay,
      endTimeOfDay,
      category,
    })).then(res => {
      if (res.type.includes('fulfilled')) {
        dispatch(getMyLeaves())
      }
    })
  }

  const HandleSetStartDate = (e: any) => {
    setStartDate(e)
    setEndDate(e)
  }

  return (
    <div>
      <LeavesDialog
        open={requestLeaveModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-leaves"
        className="modal-leaves"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            boxShadow: 'none',
            padding: '15px 20px',
            minWidth: 500,
          },
        }}
      >
        {!validated ? (
          <DialogTitle sx={{ fontSize: 20, fontWeight: 800 }} id="modal-leaves">
            Nouvelle demande d’absence
          </DialogTitle>
        ) : (
          <DialogTitle
            sx={{
              fontSize: 20, fontWeight: 800, display: 'flex', alignItems: 'center',
            }}
            id="modal-leaves"
          >
            <TaskAltIcon style={{ color: '#2c6c26', marginRight: 10, fontSize: 32 }} />
            Votre demande a bien été prise en compte
          </DialogTitle>
        )}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 25,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
        {!validated ? (
          <>
            <DialogContent>
              <CategoryPickerComponent
                handleChange={e => setCategory(e.target.value)}
                value={category}
                label="Type d'absence"
              />
              <div className="d-flex mt-24 gap-24">
                <DatePickerComponent
                  setDate={HandleSetStartDate}
                  date={startDate}
                  label="Date de début"
                  minDate={dayjs()}
                  setError={setError}
                />
                <DataPickerComponent
                  handleChange={e => setStartTimeOfDay(e.target.value)}
                  value={startTimeOfDay}
                  label="Période"
                />
              </div>
              <div className="d-flex mt-24 gap-24">
                <DatePickerComponent
                  setDate={setEndDate}
                  date={endDate}
                  label="Date de fin"
                  minDate={startDate}
                  setError={setError}
                />
                <DataPickerComponent
                  handleChange={e => setEndTimeOfDay(e.target.value)}
                  value={endTimeOfDay}
                  label="Période"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Link href="#" onClick={() => handleClose()} mr={2} sx={{ color: '#4d32e2' }}>Annuler</Link>
              <SimpleButton
                text="Soumettre la demande"
                onClick={() => handlePostLeave()}
                sx={{ marginRight: '1rem' }}
                startIcon={<SendIcon />}
                style={ButtonStyle.primary}
                disabled={error !== null}
              />
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
              Vous pouvez consulter le statut de votre demande dans
              l’onglet “Mes absences” puis dans la section “En cours”.
            </Typography>
            <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
              Vous serez informé par mail lorsque votre absence sera validée ou refusée.
            </Typography>
          </DialogContent>
        )}

      </LeavesDialog>
    </div>
  )
}
